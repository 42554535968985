import Banner from "@/components/sections/banner";
import AboutUs from '@/components/sections/about-us';
import Features from '@/components/sections/features';
import ContactUs from '@/components/sections/contact-us';
import Header from "@/components/header";
import Footer from "@/components/footer";

const Home = () => {
	return <>
		<Header />
		<Banner />
		<Features />
		<AboutUs />
		<ContactUs />
		<Footer />
	</>;
};

export default Home;