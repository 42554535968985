import '@/styles/sections/about-us.scss';
import { useTranslation } from 'react-i18next';
import { getAssets } from '@/utils/helpers';
import Reveal from 'react-awesome-reveal';
import {
	FADE_LEFT_RIGHT,
	FADE_RIGHT_LEFT,
	FADE_BOTTOM_TOP
} from '@/utils/constant/keyframes';

const AboutUs = () => {
	const { t } = useTranslation();
	return (
		<div id="Aboutus" className="about-us-wrapper">
			<img className="about-us-bg" src={require('@/' + getAssets('desktop/about-us-bg.webp', true))} alt="About us background" />
			<Reveal
				keyframes={FADE_LEFT_RIGHT}
				className="chiken ch-1"
			>
				<img className="ch-1" src={require('@/' + getAssets('ch-1.webp', true))} alt="Home chicken" />
			</Reveal>
			<Reveal
				keyframes={FADE_RIGHT_LEFT}
				className="chiken ch-2"
			>
				<img className="ch-2" src={require('@/' + getAssets('ch-2.webp', true))} alt="Away chicken" />
			</Reveal>
			<Reveal
				keyframes={FADE_BOTTOM_TOP}
				className="about-us-text-wrapper"
			>
				<div className="sub-title">
					{t('Welcome to the')}
				</div>
				<div className="title">
					{t('Ultimate gaming experience')}
				</div>
				<div className="desc">
					{t('GA28, The Leading Cockfighting Brand. We pride ourselves in providing the Best Gaming Experience to our gamers. With 150+ fights expected daily and deliver on High Definition (HD) LIVE Video, gamers can expect to earn and have fun with ease. Gamers will also have a chance to strike big in our jackpot feature and in our weekly tournaments.')}
				</div>
			</Reveal>
		</div>
	);
};

export default AboutUs;