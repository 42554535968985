import { Link } from "react-scroll";
import "@/styles/header.scss";
import HeaderLogo from "./logo.js";
import LangSwitcher from "./lang-switcher.js";
import MobileHeader from "./mobile.js";
import Reveal from "react-awesome-reveal";
import { MENU_FADE_SLIDE_DOWN } from "@/utils/constant/keyframes";
import { useTranslation } from "react-i18next";
import { useScrollSpy } from "@/hooks/useScrollSpy.js";
import { useDimensionTracker } from "../../hooks/useDimensionsTracker.js";
import { useState } from "react";

const Header = () => {
  const { t } = useTranslation();
  const ids = ["Home", "Features", "Aboutus", "Contactus"];

  const activeId = useScrollSpy(ids, 100);
  const [homeActive, setHomeActive] = useState("");
  const dimensions = useDimensionTracker();

  window.onload = (e) => {
    window.addEventListener("load", setHomeActive("Home"));
    e.preventDefault();
    return () => {
      window.removeEventListener("load", setHomeActive("Home"));
    };
  };

  const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      {dimensions > 1024 && (
        <header>
          <div className="header-logo">
            <HeaderLogo />
          </div>
          <div className="menus">
            <div className="menus-item">
              <WrapSlide delay={200}>
                <Link
                  smooth
                  spy
                  href="#Home"
                  to={`${ids[0]}`}
                  offset={-70}
                  className={ids[0] === activeId && homeActive ? "active" : ""}
                >
                  {t("Home")}
                </Link>
              </WrapSlide>
            </div>
            <div className="menus-item">
              <WrapSlide delay={200}>
                <Link
                  smooth
                  spy
                  href="#Features"
                  to={`${ids[1]}`}
                  offset={-70}
                  className={ids[1] === activeId && homeActive ? "active" : ""}
                >
                  {t("Features")}
                </Link>
              </WrapSlide>
            </div>
            <div className="menus-item">
              <WrapSlide delay={200}>
                <Link
                  smooth
                  spy
                  href="#Aboutus"
                  to={`${ids[2]}`}
                  offset={-70}
                  className={ids[2] === activeId && homeActive ? "active" : ""}
                >
                  {t("About us")}
                </Link>
              </WrapSlide>
            </div>
            <div className="menus-item">
              <WrapSlide delay={200}>
                <Link
                  smooth
                  spy
                  href="#Contactus"
                  to={`${ids[3]}`}
                  offset={-70}
                  className={ids[3] === activeId && homeActive ? "active" : ""}
                >
                  {t("Contact us")}
                </Link>
              </WrapSlide>
            </div>
            <WrapSlide delay={200}>
              <LangSwitcher />
            </WrapSlide>
            <div className="menus-item">
              <WrapSlide delay={200}>
                <Link
                  href="/demo"
                  to="#"
                  className="demo-btn"
                  onClick={() => {
                    openNewTab("/demo");
                  }}
                >
                  {t("Try demo")}
                </Link>
              </WrapSlide>
            </div>
          </div>
        </header>
      )}
      {dimensions <= 1024 && <MobileHeader />}
    </div>
  );
};

const WrapSlide = (props) => {
  return (
    <Reveal
      keyframes={MENU_FADE_SLIDE_DOWN}
      duration={1000}
      delay={props.delay}
      triggerOnce
    >
      {props.children}
    </Reveal>
  );
};

export default Header;
