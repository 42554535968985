import { Link } from 'react-router-dom';
import { getAssets } from '@/utils/helpers';
import { Slide } from "react-awesome-reveal";

const HeaderLogo = () => {
	return (
		<Slide
			direction="down"
			duration={200}
			className="logo-container"
			triggerOnce
		>
			<Link
				className="clickable"
				to="/"
			>
				<img src={require('@/' + getAssets('header-logo.webp', true))} alt="logo"/>
			</Link>
		</Slide>
	)
};

export default HeaderLogo;