import "@/styles/sections/banner.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { getAssets } from "@/utils/helpers";

const MobileBanner = () => {
  const MOBILE_BANNER = [
    {
      id: 1,
      path: "bg-1.png",
      titlePath: "banner-1.png",
      class: "slide-1"
    },
    {
      id: 2,
      path: "bg-2.png",
      titlePath: "banner-2.png",
      class: "slide-2"
    }
  ];

  const { t } = useTranslation();
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true
  };
  return (
    <Slider {...settings}>
      {MOBILE_BANNER.map((items) => (
        <div key={items.id}>
          <div className="banner-wrapper">
            <img
              src={require("@/" + getAssets(`${t(items.path)}`, false, false))}
              alt={t(items.title)}
              className="cover"
            />
            <div className={`contents ${items.class}`}>
              <img
                src={require("@/" + getAssets(items.titlePath, false, true))}
                alt={t("Cockfighting")}
              />
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default MobileBanner;
