import { useState, useEffect } from "react";
import { FLAG_LIST, DEFAULT_LANGUAGE } from '@/utils/constant';
import { getAssets } from '@/utils/helpers';
import i18n from '@/i18nextConf';
import '@/styles/sections/lang-switcher.scss';

const LangSwitcher = () => {
	const [lang, setLang] = useState({
		id: 'en',
		label: 'En',
		icon: 'flags/en.svg'
	});

	const applyLang = (e, lang) => {
		e.preventDefault();
		setLang(lang);
		i18n.changeLanguage(lang.id);
	}

	useEffect(() => {
		setLang(DEFAULT_LANGUAGE);
	}, []);

	return (
		<div className="dropdowns-container">
			<div className="dropdowns__item__wrapper">
				<div className="dropdowns__item__wrapper__content">
					<div className="dropdowns__item__wrapper__content__title">
						<img src={require('@/' + getAssets(lang.icon, true))} alt={lang.label} />
						<span className="txt">{lang.label}</span>
					</div>
					<ul className={'lang-switcher ' + lang.id}>
						{
							FLAG_LIST.map((flag) => (
								<li
									key={flag.id}
									className={(flag.id === lang.id ? 'active' : '')}
									to="#"
									onClick={(e) => applyLang(e, flag)}
								>
									<img src={require('@/' + getAssets(flag.icon, true))} alt={flag.label} />
									<span className="country-label">{flag.label}</span>
								</li>
							))
						}
					</ul>
				</div>
			</div>
		</div >
	)
};

export default LangSwitcher;