import '@/styles/sections/services.scss';
import { useTranslation } from 'react-i18next';
import { getAssets } from '@/utils/helpers';
import Reveal from 'react-awesome-reveal';
import { FADE_TOP_BOTTOM, FADE_BOTTOM_TOP } from '@/utils/constant/keyframes';

const Services = () => {
    const { t } = useTranslation();

    return <div className="services">
        <div className="services-wrap">
            <WrapSlide delay={500}>
                <div className="title g-heading">
                    <p className="sub-heading">{ t('Powerful backoffice') }</p>
                    <h2 className="heading">{ t('Unbeatable services') }</h2>
                </div>
            </WrapSlide>
            <div className="blocks">
                <div className="block">
                    <WrapSlide delay={0}>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-1.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Seamless integration') }</h3>
                            <p>{ t('Iframe solution;') }</p>
                            <p>{ t('Api odds data') }</p>
                        </div>
                    </WrapSlide>
                </div>
                <div className="block">
                    <WrapSlide delay={150}>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-2.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Powerful backoffice tool') }</h3>
                            <p>{ t('Members behavior and reports') }</p>
                        </div>
                    </WrapSlide>
                </div>
                <div className="block">
                    <WrapSlide delay={300}>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-3.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Language & currencies') }</h3>
                            <p>{ t('Fully localized into multiple language and currencies; multiple wallet transfer') }</p>
                        </div>
                    </WrapSlide>
                </div>
                <div className="block">
                    <WrapSlide delay={450}>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-4.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Customer service b2b') }</h3>
                            <p>{ t('24/7 Customer service for support at any hour') }</p>
                        </div>
                    </WrapSlide>
                </div>

                <div className="block">
                    <WrapSlide delay={450} slideTop>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-5.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Extensive choices of esports markets') }</h3>
                            <p>{ t('Most markets vhoices for prematch and inplay') }</p>
                        </div>
                    </WrapSlide>
                </div>
                <div className="block">
                    <WrapSlide delay={300} slideTop>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-6.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Product certification') }</h3>
                            <p>{ t('Obtained gli gaming certification standard, trusted and fair system stable profit') }</p>
                        </div>
                    </WrapSlide>
                </div>
                <div className="block">
                    <WrapSlide delay={150} slideTop>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-1.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Leading the market') }</h3>
                            <p>{ t('Most number of inplay market offering highest uptime for inplay markets') }</p>
                        </div>
                    </WrapSlide>
                </div>
                <div className="block">
                    <WrapSlide delay={150} slideTop>
                        <div>
                            <img
                                src={require('@/' + getAssets('services-8.png', true))}
                                alt="img"
                            />
                            <h3>{ t('Excellent betting experience') }</h3>
                            <p>{ t('Fast and stable system, double betting speed') }</p>
                        </div>
                    </WrapSlide>
                </div>
            </div>
        </div>
    </div>;
}

const WrapSlide = (props) => {
    const direction = props.slideTop ? FADE_BOTTOM_TOP : FADE_TOP_BOTTOM; 
	return <Reveal
		keyframes={direction}
		delay={props.delay}
        className="block-wrap"
	>
		{props.children}
	</Reveal>;
}

export default Services;