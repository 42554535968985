import '@/styles/sections/lootbox.scss';
import Reveal from 'react-awesome-reveal';
import {
    FADE_LEFT_RIGHT,
    FADE_RIGHT_LEFT,
    FADE_TOP_BOTTOM,
} from '@/utils/constant/keyframes';
import { getAssets } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';

const Lootbox = () => {
    const { t } = useTranslation();
	return (
        <div className="lootbox">
            <div className="lootbox-title">
                <Reveal keyframes={FADE_TOP_BOTTOM}>
                    <h2>
                        <span className="lg">{ t('Esports') }</span>
                        <span className="sm">{ t('and') }</span>
                        <span className="lg">{ t('Lootbox') }</span>
                    </h2>
                </Reveal>
                <img
                    src={require('@/' + getAssets('lootbox-bg.jpg', true))}
                    alt="device"
                />
            </div>
            <div className="lootbox-features">
                <div className="lootbox-features-wrap">
                    <div className="features-device">
                        <img
                            src={require('@/' + getAssets('feature-device.png', true))}
                            alt="device"
                        />
                    </div>
                    <ul className="features-list left">
                        <Reveal delay={50} keyframes={FADE_LEFT_RIGHT}><li>{ t('Unbeatable odds speedy settlement') }</li></Reveal>
                        <Reveal delay={150} keyframes={FADE_LEFT_RIGHT}><li>{ t('Extensive choices of esports markets') }</li></Reveal>
                        <Reveal delay={300} keyframes={FADE_LEFT_RIGHT}><li>{ t('Vastly experienced and skilled esports traders') }</li></Reveal>
                    </ul>
                    
                    <ul className="features-list right">
                        <Reveal delay={50} keyframes={FADE_RIGHT_LEFT}><li>{ t('Fast and stable system, double betting speed') }</li></Reveal>
                        <Reveal delay={150} keyframes={FADE_RIGHT_LEFT}><li>{ t('Supports pcs, mobile phones and tablets') }</li></Reveal>
                        <Reveal delay={300} keyframes={FADE_RIGHT_LEFT}><li>{ t('Industry leading betting experience') }</li></Reveal>
                    </ul>
                </div>
            </div>
        </div>
	)
};
  
export default Lootbox;