import "@/styles/sections/contact.scss";
import "react-phone-number-input/style.css";

import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import { useForm, Controller } from "react-hook-form";
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Reveal from 'react-awesome-reveal';
import {
    FADE_BOTTOM_TOP
} from '@/utils/constant/keyframes';

const ContactUs = () => {
    const { t } = useTranslation();
    const form_email = useRef();
    const {
        register,
        handleSubmit,
        reset,
        trigger,
        control,
        formState: { errors },
    } = useForm();
    const [number, setCountries] = useState("");
    const [isContact, setContact] = useState(false);
    const [Success, setShowSuccess] = useState(false);
    const [MainContact, setShowMain] = useState(true);

    const onSubmit = (data) => {
        console.log('onsubmit triggered');
        if (data.phone.length <= 8) {
            setContact(true);
        } else {
            setShowSuccess(true);
            setShowMain(false);
            setCountries("");
            data.received = "CF Contact Us";
            emailjs
                .sendForm(
                    "service_tfgaming_contact",
                    "template_silwupg",
                    form_email.current,
                    "5ZD_aYGqr6EsG1Y3W"
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
            reset();
        }
    };

    const reInit = () => {
        setShowMain(true);
        setShowSuccess(false);
        setContact(false);
    };

    return (
        <div id="Contactus" className="contact-box">
            <Reveal
                keyframes={FADE_BOTTOM_TOP}
                className="contact-box-wrap-right"
            >
                <div className="contact-box-wrap">
                    {MainContact && (
                        <form ref={form_email} onSubmit={handleSubmit(onSubmit)} noValidate>
                            <h2 className="heading">{t("Contact us")}</h2>
                            <div className="sub-heading">
                                {t("Have a question or need assistance? Fill out the form below, and we’ll get back to you promptly.")}
                            </div>
                            {errors.name && (
                                <small className="errorMessage">{t(errors.name.message)}</small>
                            )}
                            <input
                                type="text"
                                name="name"
                                className={`form-control ${errors.name && "invalid"}`}
                                {...register("name", {
                                    required: {
                                        value: true,
                                        message: "Please enter your name.",
                                    },
                                    minLength: {
                                        value: 2,
                                        message: "Please enter your name.",
                                    },
                                })}
                                onKeyUp={() => {
                                    trigger("name");
                                }}
                                placeholder={t("Name*")}
                                required
                            ></input>
                            {errors.email && (
                                <small className="errorMessage">
                                    {t(errors.email.message)}
                                </small>
                            )}
                            <input
                                type="text"
                                name="email"
                                className={`form-control ${errors.email && "invalid"}`}
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: "Please enter your email address.",
                                    },
                                    pattern: {
                                        value:
                                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: "Please enter your email address.",
                                    },
                                })}
                                onKeyUp={() => {
                                    trigger("email");
                                }}
                                placeholder={t("Email*")}
                                required
                            ></input>
                            <div
                                name="receive"
                                id="received"
                                {...register("received", {
                                    required: false,
                                    validate: {
                                        rule1(value) {
                                            if (value.length <= 8) return setContact(true);
                                        },
                                    },
                                })}
                                className={`form-phone ${isContact && "invalid"} ${errors.phone && "invalid"
                                    }`}
                            >
                                <label>{t("Contact number (please add country code)")}</label>
                                {errors.phone && (
                                    <small className="errorMessage">
                                        {t("Please enter your contact number.")}
                                    </small>
                                )}
                                <div className="phone">
                                    <div className="container">
                                        <div className="inline">
                                            <Controller
                                                name="phone"
                                                control={control}
                                                render={({ field: { ...field } }) => (
                                                    <PhoneInput
                                                        {...field}
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="VN"
                                                        international
                                                        value={number && formatPhoneNumberIntl(number)}
                                                        {...register("phone", {
                                                            required: false,
                                                            validate: {
                                                                rule1(value) {
                                                                    if (value.length >= 8)
                                                                        return setContact(false);
                                                                },
                                                            },
                                                            maxLength: {
                                                                value: 20,
                                                            },
                                                        })}
                                                        onChange={(text) => {
                                                            setCountries(text);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errors.company && (
                                <small className="red">
                                    {t("Please enter your company.")}
                                </small>
                            )}
                            <input
                                type="text"
                                name="company"
                                className="form-control"
                                {...register("company", {
                                    required: {
                                        value: false,
                                    },
                                })}
                                placeholder={t("Company")}
                            ></input>
                            <div className="enter-message">
                                {errors.message && (
                                    <small className="red">
                                        {t("Please enter your message.")}
                                    </small>
                                )}
                                <textarea
                                    {...register("message", {
                                        required: true,
                                        minLength: {
                                            value: 2,
                                            message: `${t("Please enter your message.")}`,
                                        },
                                    })}
                                    onKeyUp={() => {
                                        trigger("message");
                                    }}
                                    className={`form-control ${errors.message && "invalid"}`}
                                    placeholder={t("Your message*")}
                                    name="message"
                                ></textarea>
                                {errors.message && <span className="red"></span>}
                            </div>
                            <div className="btn-div">
                                <button
                                    type="submit"
                                    title={t("Send message")}
                                >
                                    {t("Send message")}
                                </button>
                            </div>
                        </form>
                    )}
                    {Success && (
                        <div className="div_succes">
                            <h2 className="heading">{t("Thanks!")}</h2>
                            <h2 className="message">{t("Your message has been sent.")}</h2>
                            <h2 className="message">{t("We'll get in touch with you shortly.")}</h2>
                            <button
                                className="success_btn"
                                onClick={() => reInit()}
                            >
                                {t("Okay")}
                            </button>
                        </div>
                    )}

                </div>
            </Reveal>
        </div >
    );
};
export default ContactUs;
