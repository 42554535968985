import '@/styles/404.scss';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
	const { t } = useTranslation();
	return (
		<div className="not-found-wrapper">
			<div className="title">
				{t('Error 404')}
			</div>
			<div className="sub-title">
				{t('Page not found')}
			</div>
		</div>
	)
};

export default PageNotFound;