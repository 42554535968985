const BREAKPOINTS = {
    desktop: 1440,
    ipad_pro: 1024,
    ipad_pro_portrait: 1366,
    ipad: 768,
    mobile: 429,
};

const IMAGE_ASSETS = 'assets/images';

const LANGUAGES = ['zh', 'vn', 'id', 'ms', 'en'];

const DEFAULT_LANGUAGE = {
    id: 'en',
    label: 'Eng',
    icon: 'flags/en.svg'
};

const FLAG_LIST = [
    {
        id: 'vn',
        label: 'Viet',
        icon: 'flags/vn.svg'
    },
    {
        id: 'id',
        label: 'Indo',
        icon: 'flags/id.svg'
    },
    {
        id: 'th',
        label: 'Thai',
        icon: 'flags/th.svg'
    },
    {
        id: 'en',
        label: 'Eng',
        icon: 'flags/en.svg'
    }
];

export {
    BREAKPOINTS,
    IMAGE_ASSETS,
    LANGUAGES,
    DEFAULT_LANGUAGE,
    FLAG_LIST
}