import "@/styles/header_mobile.scss";
import LangSwitcher from "./lang-switcher";
import HeaderLogo from './logo.js';
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { useState } from "react";
import { useScrollSpy } from "@/hooks/useScrollSpy.js";

const MobileHeader = () => {
    const { t } = useTranslation();
    const ids = [
        "Home",
        "Features",
        "Aboutus",
        "Contactus",
    ];
    const activeId = useScrollSpy(ids, 100);
    const [navbar, showNav] = useState(true);
    const homeActive = "";
    const NavBar = (event, val) => {
        showNav(event);
    };

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <header className="mobile">
            {navbar ? (
                <div className="cont">
                    <div className="mobile-logo">
                        <HeaderLogo />
                    </div>
                    {/* <Link
                        href="/demo"
                        className="demo-btn"
                        onClick={() => {
                            openNewTab("/demo");
                        }}
                    >
                        {t('Try demo')}
                    </Link> */}
                    <div className="menu__burger" onClick={() => NavBar(false)}>
                        <span className="menu__burger__span"></span>
                    </div>
                </div>
            ) : (
                <div className="hamburger-menu">
                    <div className="menu__burger" onClick={() => NavBar(true)}>
                        <span className="menu__burger__span menu__burger__open"></span>
                    </div>
                    <div className="hamburger-menu-container">
                        <Link
                            smooth
                            spy
                            href="#Home"
                            to={`${ids[0]}`}
                            offset={-70}
                            onClick={() => NavBar(true)}
                            className={
                                ids[0] === activeId && homeActive ? "active" : ""
                            }
                        >
                            {t('Home')}
                        </Link>
                        <Link
                            smooth
                            spy
                            href="#Features"
                            to={`${ids[1]}`}
                            offset={-70}
                            onClick={() => NavBar(true)}
                            className={
                                ids[1] === activeId && homeActive ? "active" : ""
                            }
                        >
                            {t('Features')}
                        </Link>
                        <Link
                            smooth
                            spy
                            href="#Aboutus"
                            to={`${ids[2]}`}
                            offset={-70}
                            onClick={() => NavBar(true)}
                            className={
                                ids[2] === activeId && homeActive ? "active" : ""
                            }
                        >
                            {t('About us')}
                        </Link>
                        <Link
                            smooth
                            spy
                            href="#Contactus"
                            to={`${ids[3]}`}
                            offset={-70}
                            onClick={() => NavBar(true)}
                            className={
                                `contact-btn ${ids[3] === activeId && homeActive ? "active" : ""}`
                            }
                        >
                            {t('Contact us')}
                        </Link>
                        <div className="mobile-language" >
                            <LangSwitcher />
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
};

export default MobileHeader;
