import '@/styles/footer.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="footer-wrapper">
            {t('Copyright @ 2023 GA28. All rights reserved.')}
        </div>
    );
};

export default Footer;