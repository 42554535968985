import '@/styles/sections/exclusive.scss';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { getAssets } from '@/utils/helpers';
import Reveal from 'react-awesome-reveal';
import { FADE_TOP_BOTTOM } from '@/utils/constant/keyframes';

const Exclusive = () => {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        infinite: true,
        autoPlay: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return <div className="exclusive">
        <div className="exclusive-wrap">
            <div className="exclusive-title">
                <div className="details g-heading">
                    <Reveal keyframes={FADE_TOP_BOTTOM}>
                        <p className="sub-heading">{ t('Tf exclusive') }</p>
                        <h2 className="heading">{ t('Lootbox') }</h2>
                        <p className="desc"><span>{ t('Lootbox games') }</span> { t('are Provably Fair games that based on special algorithm that helps players to ensure the games results are truly unbiased and fair. Players can personally verify the randomness and fariness of the games using our Verification system. Giving players an opportunity to enjoy the games safely.') } </p>
                        <button>{ t('Try demo') }</button>
                    </Reveal>
                </div>
            </div>
            <div className="exclusive-contents">
                <Slider {...settings}>
                    <Reveal keyframes={FADE_TOP_BOTTOM} delay={150}>
                        <div>
                            <div className="exclusive-block">
                                <div className="thumb">
                                    <img
                                        src={require('@/' + getAssets('exclusive-csgo.png', true))}
                                        alt="img"
                                    />
                                </div>
                                <div className="desc">
                                    <p>{ t('CSGO Defuse is a time-critical riveting game where your mission is to prevent an explosion by defusing the bomb planted by the terrorists. The clock is ticking. Don’t let the terrorists win!') }</p>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal keyframes={FADE_TOP_BOTTOM} delay={300}>
                        <div>
                            <div className="exclusive-block">
                                <div className="thumb">
                                    <img
                                        src={require('@/' + getAssets('exclusive-loldraft.png', true))}
                                        alt="img"
                                    />
                                </div>
                                <div className="desc">
                                    <p>{ t('LOL Draft simulates from Blind Ban Drafting; your task is to predict the champions that will be picked for BAN /  TOP / JUNGLE / MID / BOT / SUPPORT.') }</p>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal keyframes={FADE_TOP_BOTTOM} delay={450}>
                        <div>
                            <div className="exclusive-block">
                                <div className="thumb">
                                    <img
                                        src={require('@/' + getAssets('exclusive-dotatreasure.png', true))}
                                        alt="img"
                                    />
                                </div>
                                <div className="desc">
                                    <p>{ t('DOTA Treasure is a thrilling game of fortune and anticipation unlike any other roulette games. With a treasure lootbox containing Rarity Skins.') }</p>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal keyframes={FADE_TOP_BOTTOM} delay={600}>
                        <div>
                            <div className="exclusive-block">
                                <div className="thumb">
                                    <img
                                        src={require('@/' + getAssets('exclusive-kogdraw.png', true))}
                                        alt="img"
                                    />
                                </div>
                                <div className="desc">
                                    <p>{ t('KOG DRAW is  a   crypto-oriented game where your task is to predict the attributes (Gender, Range, Class) of the next Hero Card.') }</p>
                                </div>
                            </div>
                        </div>
                        </Reveal>
                </Slider>
            </div>
        </div>
    </div>;
}

export default Exclusive;