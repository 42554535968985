import "@/styles/sections/banner.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { getAssets } from "@/utils/helpers";
import MobileBanner from "./mobile";
import { useDimensionTracker } from "@/hooks/useDimensionsTracker.js";

const Banner = () => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true
  };
  const dimensions = useDimensionTracker();

  return (
    <div id="Home" className="home-wrapper">
      {dimensions > 600 ? (
        <Slider {...settings}>
          <div className="banner-1">
            <img
              src={require("@/" + getAssets("bg-1.png", false, false))}
              alt="cover"
              className="cover"
            />
            <div className="contents slide-1">
              <img
                src={require("@/" + getAssets("banner-1.png", false, true))}
                alt={t("Cockfighting")}
              />
            </div>
          </div>
          <div>
            <img
              src={require("@/" + getAssets("bg-2.png", false, false))}
              alt="banner"
              className="cover"
            />
            <div className="contents slide-2">
              <img
                src={require("@/" + getAssets("banner-2.png", false, true))}
                alt={t("Cockfighting")}
              />
            </div>
          </div>
        </Slider>
      ) : (
        dimensions <= 600 && <MobileBanner />
      )}
    </div>
  );
};

export default Banner;
