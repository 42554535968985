import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import '@/styles/demo.scss';

const Demo = () => {
	const navigate = useNavigate();
	const [launchUrl, setIframeLaunchURL] = useState("");
	const onInit = () => {
		const options = {
			method: "POST",
			data: {
				"public_key": "6c8e58fb-7cbf-48dd-bda1-a5011b77a8d5",
				"private_key": "4cc0b7d2-6fec-4080-869f-7e561e37f739"
			},
			url: "https://partner-api.ga2828.com/api/v1/auth/login",
		};
		axios(options)
			.then((response) => {
				const { token } = response.data;
				if (token) {
					getGCLoginURL(token);
				}
			})
			.catch((error) => {
				return error;
			});
	};
	const getGCLoginURL = (token) => {
		const refId = 'gademo' + (Math.floor(Math.random() * 100) % 30 + 1);
		const options = {
			method: "POST",
			headers: {
				Authorization: token,
			},
			data: {
				"ref_id": refId,
				"account_id": "4f8a14ed-bdfb-4013-8463-5678e3da9292"
			},
			url: "https://partner-api.ga2828.com/api/v1/members/login",
		};
		axios(options)
			.then((response) => {
				if (response.data.game_client_url) {
					navigate("");
					const token = response.data.token;
					const tempLaunchUrl = 'https://www.dg8-api.com/launch?launch_token=' + token;
					setIframeLaunchURL(tempLaunchUrl);
				} else {
					throw new Error("Launch url is undefined");
				}
				return response;
			})
			.catch((error) => {
				return error;
			});
	};
	useEffect(() => {
		navigate("");
		const randomDemoId = Math.floor(Math.random() * 100) % 29 + 1;
		const tempLaunchUrl = `https://www.dg8-api.com/launch?auth=B5C445CE-569E-4ED9-933D-2287702DCD14&token=gademo${randomDemoId}`;
		setIframeLaunchURL(tempLaunchUrl);
	}, [])
	return (
		<div className="iframe-wrapper">
			<iframe
				id="demoIframe"
				src={launchUrl}
				width="100%"
				height="100%"
				title="myFrame"
				allowFullScreen
			></iframe>
		</div>
	)
};

export default Demo;
