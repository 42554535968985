import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { LANGUAGES, DEFAULT_LANGUAGE } from '@/utils/constant';

const fallbackLng = [DEFAULT_LANGUAGE.id];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng, // selected language
    detection: {
      checkWhitelist: true, // options for language detection
    },
    debug: false,
    whitelist: LANGUAGES, // language list
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;