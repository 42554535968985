import '@/styles/sections/features.scss';
import Reveal from 'react-awesome-reveal';
import {
    FADE_LEFT_RIGHT,
    FADE_RIGHT_LEFT,
    FADE_BOTTOM_TOP
} from '@/utils/constant/keyframes';
import { getAssets } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';
import { useDimensionTracker } from "../../hooks/useDimensionsTracker.js";

const AboutUs = () => {
    const { t } = useTranslation();
    const dimensions = useDimensionTracker();
    return (
        <div>
            {dimensions > 1024 && (
                <div id="Features" className="features">
                    <div className="features-wrap">
                        <Reveal
                            keyframes={FADE_LEFT_RIGHT}
                            className="about-contents"
                        >
                            <div className="g-heading">
                                <h2 className="heading">{t('Bet with confidence, win with reliability!')}</h2>
                                <p className="desc">{t('Cockfighting has been in existence for more than 6,000 years in various parts of the world. Countries like Cuba, Mexico, Peru, Brazil, India, Indonesia, China, Japan and Central America among others have recorded history of cockfighting. The popularity of this sport has no boundaries.')} </p>
                                <div className="key-points">
                                    <Reveal keyframes={FADE_BOTTOM_TOP} delay={150}>
                                        <div className="key-points-item">
                                            <img
                                                src={require('@/' + getAssets('easy-to-use.webp', true))}
                                                alt="Easy to use"
                                            />
                                            <div className="sub-title">
                                                {t('Easy to use interface')}
                                            </div>
                                            <div className="desc">
                                                {t('Explore and watch live cockfighting games with ease. Place your choices effortlessly.')}
                                            </div>
                                        </div>
                                    </Reveal>
                                    <Reveal keyframes={FADE_BOTTOM_TOP} delay={300}>
                                        <div className="key-points-item">
                                            <img
                                                src={require('@/' + getAssets('weekly-jackpot.webp', true))}
                                                alt="Weekly jackpot"
                                            />
                                            <div className="sub-title">
                                                {t('Jackpot and weekly tournaments')}
                                            </div>
                                            <div className="desc">
                                                {t('Gamers can amass significant earnings while competing for leaderboard supremacy.')}
                                            </div>
                                        </div>
                                    </Reveal>
                                    <Reveal keyframes={FADE_BOTTOM_TOP} delay={450}>
                                        <div className="key-points-item">
                                            <img
                                                src={require('@/' + getAssets('clean-road-map.webp', true))}
                                                alt="Clean road map"
                                            />
                                            <div className="sub-title">
                                                {t('Clean road map score card')}
                                            </div>
                                            <div className="desc">
                                                {t('Gamers can analyze round trends for quick predictions in fast-settling games.')}
                                            </div>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>
                        </Reveal>
                        <Reveal
                            cascade={true}
                            keyframes={FADE_RIGHT_LEFT}
                            className="about-img"
                        >
                            <img
                                src={require('@/' + getAssets('about-us-img.webp', true))}
                                alt="About us"
                                className="cf-bg"
                            />
                        </Reveal>
                    </div>
                </div>
            )}
            {dimensions <= 1024 && (
                <div id="Features" className="features mobile">
                    <div className="features-wrap">
                        <Reveal
                            keyframes={FADE_BOTTOM_TOP}
                            className="about-contents"
                        >
                            <div className="g-heading">
                                <h2 className="heading">{t('Bet with confidence, win with reliability!')}</h2>
                                <p className="desc">{t('Cockfighting has been in existence for more than 6,000 years in various parts of the world. Countries like Cuba, Mexico, Peru, Brazil, India, Indonesia, China, Japan and Central America among others have recorded history of cockfighting. The popularity of this sport has no boundaries.')} </p>
                                <Reveal
                                    cascade={true}
                                    keyframes={FADE_BOTTOM_TOP}
                                    className="about-img"
                                >
                                    <img
                                        src={require('@/' + getAssets('about-us-img.webp', true))}
                                        alt="About us"
                                        className="cf-bg"
                                    />
                                </Reveal>
                                <div className="key-points">
                                    <Reveal keyframes={FADE_BOTTOM_TOP} delay={150}>
                                        <div className="key-points-item">
                                            <img
                                                src={require('@/' + getAssets('easy-to-use.webp', true))}
                                                alt="Easy to use"
                                            />
                                            <div className="sub-title">
                                                {t('Easy to use interface')}
                                            </div>
                                            <div className="desc">
                                                {t('Explore and watch live cockfighting games with ease. Place your choices effortlessly.')}
                                            </div>
                                        </div>
                                    </Reveal>
                                    <Reveal keyframes={FADE_BOTTOM_TOP} delay={300}>
                                        <div className="key-points-item">
                                            <img
                                                src={require('@/' + getAssets('weekly-jackpot.webp', true))}
                                                alt="Weekly jackpot"
                                            />
                                            <div className="sub-title">
                                                {t('Jackpot and weekly tournaments')}
                                            </div>
                                            <div className="desc">
                                                {t('Gamers can amass significant earnings while competing for leaderboard supremacy.')}
                                            </div>
                                        </div>
                                    </Reveal>
                                    <Reveal keyframes={FADE_BOTTOM_TOP} delay={450}>
                                        <div className="key-points-item">
                                            <img
                                                src={require('@/' + getAssets('clean-road-map.webp', true))}
                                                alt="Clean road map"
                                            />
                                            <div className="sub-title">
                                                {t('Clean road map score card')}
                                            </div>
                                            <div className="desc">
                                                {t('Gamers can analyze round trends for quick predictions in fast-settling games.')}
                                            </div>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>
                        </Reveal>

                    </div>
                </div>
            )}
        </div>
    );
};

export default AboutUs;