import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "@/layouts/index";
import Home from "@/views/home";
import NotFound from "@/views/404.js";
import Demo from "@/views/demo";

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="/demo" element={<Demo />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
};

export default Router;